'use client'

import { useRowLabel } from '@payloadcms/ui'

export const ClientArrayRowLabel = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
  const { data, rowNumber } = useRowLabel()

  const label =
    (data as { link: { label: string } })?.link?.label ??
    (data as { heading: string })?.heading ??
    ''

  return `Item ${rowNumber}: ${label}`
}
